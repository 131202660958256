import cloneDeep from 'lodash.clonedeep';
import {LangName} from '~/config/cookieKeys'
import { default_country, default_currency, default_good_lang, default_site_lang, GET_SITE_ID } from '~/config'
import {PageEnum, PageNameEnum} from "~/enums/pageEnum";
const getType = obj => Object.prototype.toString.call(obj);

// const isObject = (target) => (typeof target === 'object' || typeof target === 'function') && target !== null;

// const canTraverse = {
//   '[object Map]': true,
//   '[object Set]': true,
//   '[object Array]': true,
//   '[object Object]': true,
//   '[object Arguments]': true,
// };
// const mapTag = '[object Map]';
// const setTag = '[object Set]';
// const boolTag = '[object Boolean]';
// const numberTag = '[object Number]';
// const stringTag = '[object String]';
// const symbolTag = '[object Symbol]';
// const dateTag = '[object Date]';
// const errorTag = '[object Error]';
// const regexpTag = '[object RegExp]';
// const funcTag = '[object Function]';

// const handleRegExp = (target) => {
//   const { source, flags } = target;
//   return new target.constructor(source, flags);
// }

// const handleFunc = (func) => {
//   // 箭头函数直接返回自身
//   if(!func.prototype) return func;
//   const funcString = func.toString();
//   // eslint-disable-next-line no-new-func
//   return new Function('return ' +  funcString);
// }

// const handleNotTraverse = (target, tag) => {
//   const Ctor = target.constructor;
//   switch(tag) {
//     case boolTag:
//       // eslint-disable-next-line no-new-object
//       return new Object(Boolean.prototype.valueOf.call(target));
//     case numberTag:
//       // eslint-disable-next-line no-new-object
//       return new Object(Number.prototype.valueOf.call(target));
//     case stringTag:
//       // eslint-disable-next-line no-new-object
//       return new Object(String.prototype.valueOf.call(target));
//     case symbolTag:
//       // eslint-disable-next-line no-new-object
//       return new Object(Symbol.prototype.valueOf.call(target));
//     case errorTag:
//     case dateTag:
//       return new Ctor(target);
//     case regexpTag:
//       return handleRegExp(target);
//     case funcTag:
//       return handleFunc(target);
//     default:
//       return new Ctor(target);
//   }
// }

// eslint-disable-next-line no-unused-vars
// const deepClone = (target, map = new WeakMap()) => {
//   if(!isObject(target))
//     return target;
//   const type = getType(target);
//   let cloneTarget;
//   if(!canTraverse[type]) {
//     // 处理不能遍历的对象
//     return handleNotTraverse(target, type);
//   }else {
//     // 这波操作相当关键，可以保证对象的原型不丢失！
//     const ctor = target.constructor;
//     // eslint-disable-next-line new-cap
//     cloneTarget = new ctor();
//   }

//   if(map.get(target))
//     return target;
//   map.set(target, true);

//   if(type === mapTag) {
//     // 处理Map
//     target.forEach((item, key) => {
//       cloneTarget.set(deepClone(key, map), deepClone(item, map));
//     })
//   }

//   if(type === setTag) {
//     // 处理Set
//     target.forEach(item => {
//       cloneTarget.add(deepClone(item, map));
//     })
//   }

//   // 处理数组和对象
//   for (const prop in target) {
//     // eslint-disable-next-line no-prototype-builtins
//     if (target.hasOwnProperty(prop)) {
//       cloneTarget[prop] = deepClone(target[prop], map);
//     }
//   }
//   return cloneTarget;
// }

function transMoney(value, dir = 2){
  let num = ''
  if (value === 0) {
    return parseFloat(value).toFixed(dir);
  }
  if (value !== '') {
    value += ''
    value = parseFloat(value.replace(/,/g, '')).toFixed(dir);
    if (!value.includes(".")) {
      num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
        return s + ',';
      });
    } else {
      num = value.replace(/(\d)(?=(\d{3})+\.)/g, function(s) {
        return s + ',';
      });
    }
  } else {
    num = ""
  }
  return num
}


const isNumber = t => Object.prototype.toString.call(t).includes('Number')

const createId = (list = [], key='') => {
  if (!list.length || key === '') return '';
  function compare (key) {
    return function (a,b) {
      const v1 = a[key];
      const v2 = b[key];
      return v1 - v2;
    }
  }
  const result = list.sort(compare(key))
  return result[result.length - 1][key]+1;
}


const getQueryString = (name, url) => {
  const reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
  const r = url || window.location.search.substr(1).match(reg);
  if(r!=null)return  unescape(r[2]); return null;
}

//  获取商城语言环境
const getLang = (ctx) => {
  const that = ctx || ctx.app;
  const lang = that.$cookies.get(LangName) || 'en'
  if ( lang === 'zh') {
    return 'cn'
  } else {
    return lang
  }
}

//  从path中获取商城语言环境
const getLangFromPath = (path) => {
  let lang = 'en';
  const pathSplit = path.split('/')
  let langIndex = 0;
  if (path.includes('preview')) { // 预览环境
    langIndex = 3
  } else { // 线上环境
    langIndex = 1
  }

  lang = pathSplit[langIndex] || 'en';

  return lang === 'zh' ? 'cn' : lang;
}

//   通过ip获取货币环境
const getLangByip = (ctx) => {
  const that = ctx || ctx.app;
  const lang = that.$store.state.user.ipInfo
  return lang
}

const transformLangToServer = (langStr) => {
  if (langStr === 'zh') {
    return 'cn'
  }
  return langStr
}

// 获取商店语言环境
// 设计时：固定为en
// 运行时：优先取路由参数，路由参数没有建站器的default_language; 如果改商品多语言设置了该语言环境，则显示该语言环境语言，否则显示en环境语言
const getStoreLang = (that) => {
  if (that.editing) {
    return default_site_lang;
  }
  const projectInfo = that.$store.state.project?.info?.project;
  // eslint-disable-next-line
  const { default_language, languages } = projectInfo;
  const lang = getNeedParamsFormRouteParams(that.$route.params)?.lang || default_language;

  // 网站语言包括在商品语言中，商品跟随网站路由中的lang语言；反之商品显示商品默认语言
  if (languages && languages.includes(lang)) {
    return lang
  } else {
    // eslint-disable-next-line
    return default_site_lang;
  }
}

// 动态加载js fix: 解决head()方法中异步加载完成无通知问题
const loadJsAsync = (src, async, options) => {
  return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      if (options) {
          for (const key in options) {
              script.setAttribute(key, options[key]);
          }
      }

      const onload = () => {
          /// console.info("js loaded: ", src);
          script.removeEventListener("load", onload);
          resolve();
      };

      script.addEventListener("load", onload);
      script.addEventListener("error", (err) => {
          script.removeEventListener("load", onload);
        // eslint-disable-next-line no-console
          console.error("loading js error: ", src, err);
          reject(new Error(`Failed to load ${src}`));
      });

      (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
  });
}
const formatDate = (timestamp, format) => {
  // todo 根据传日格式返回
  // timestamp 精确度为毫秒
  const date = new Date(timestamp);
  const obj = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    Minute: date.getMinutes(),
    seconds: date.getSeconds()
  }
  if (!format) {
    return `${obj.year}-${obj.month}-${obj.day} ${obj.hour <= 9 ? '0'+obj.hour : obj.hour}:${obj.Minute <= 9 ? '0'+obj.Minute : obj.Minute}:${obj.seconds<=9 ? '0'+obj.seconds : obj.seconds}`
  }
}

// 获取localStorage X-Forwarded-For值
const getxfip = (localStorage) => {
  let forwarded = {};
  // release 不加 X-Forwarded-For (暂时注释测试)
  // if (isRelease) {
  //   return forwarded;
  // }
  if (!localStorage) {
    // eslint-disable-next-line
    console.error('请传入localStorage')
    return forwarded;
  }
  const ip = localStorage.getItem('X-Forwarded-For') || '';
  if (ip) {
    forwarded = { 'X-Forwarded-For': ip }
  }
  return forwarded;
}


// 不能含小数点的货币，country_currency[]，使用向上取整
const ZERO_DECIMAL_CURRENCIES = [
  'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'IDR',
  'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF',
  'HKD', 'TWD'
]

/**
 * 全建站器统一获取价格方法
 * 优先取ip对应货币价格，没有取usd, 再没有显示没有价格，暂时不支持显示0元
 *
 * @param {*} pricesSetting
 * @param {*} ipInfo
 * @param {*} that
 * @param {*} type - defaultPrice | originalPrice
 * @returns
 */
const getPriceInfo = (pricesSetting = {}, ipInfo = {
  currency: 'USD',country: 'US'
}, that = null, type = 'defaultPrice') => {
  if(!pricesSetting) return { currency: '', currencyLabel: '', price: '', riceTxt: '' }
  const valKey = type === 'defaultPrice'
    ? 'default_value'
    : 'original_value'
  const {currency, country}  = ipInfo
  const currencyMap = that.$store.state.project.currencieMap
  let info = {
    currency: 'USD',
    currencyLabel: '$',
    price: null,
    priceTxt: that.$i18n.t('siteBuild.store.noPrice')
  }
  if (!Object.keys(pricesSetting).length) {
    // eslint-disable-next-line no-console
    console.error('请传入价格对象：prices')
    return info;
  }
  if (!currency) {
    // eslint-disable-next-line no-console
    console.error('请传入默认货币：currency')
    return info;
  }
  if (!that) {
    // eslint-disable-next-line no-console
    console.error('请传入this', country)
    return info;
  }
  let price = 0
  if (pricesSetting[country] && pricesSetting[country][currency]) {
    price = pricesSetting[country][currency][valKey].toFixed(2)
  }
  const currencyLabel = currencyMap[currency]?.label;
  // 不能含小数的货币，向上取整
  const isIntPrice = ZERO_DECIMAL_CURRENCIES.includes(currency)
  if (isIntPrice) {
    price = Math.ceil(price)
  }
  if (price) {
    info = {
      currency,
      currencyLabel,
      price,
      priceTxt: `${currencyLabel} ${price}`
    }
  } else if (pricesSetting[default_country]) {
    let defaultPrice = pricesSetting[default_country] && pricesSetting[default_country][default_currency]
      ? pricesSetting[default_country][default_currency][valKey]
      : 0
    // 不能含小数的货币，向上取整
    const isIntPrice = ZERO_DECIMAL_CURRENCIES.includes(default_currency)
    if (isIntPrice) {
      defaultPrice = Math.ceil(defaultPrice)
    } else {
      defaultPrice = defaultPrice.toFixed(2)
    }
    info = {
      currency: default_currency,
      currencyLabel: currencyMap[default_currency]?.label,
      price: defaultPrice,
      priceTxt: defaultPrice
        ? `${currencyMap[default_currency]?.label} ${defaultPrice}`
        : that.$i18n.t('siteBuild.store.noPrice')
    }
  } else {
    info = {
      currency,
      currencyLabel: '$',
      price: 0,
      priceTxt: that.$i18n.t('siteBuild.store.noPrice'),
    }
  }
  return info;
}
// 全建站器统一获取商品信息名称，描述信息方法
const getGoodInfo = (names={}, descs={}, lang='', that=null, langIdData = {}) => {
  const info = {
    name: '',
    desc: '',
  }
  // if (!Object.keys(names).length) {
  //   console.error('请传入名称对象： names')
  //   return info;
  // }
  if (!lang) {
    // eslint-disable-next-line no-console
    console.error('请传入语言环境变量：lang')
    return info;
  }
  if (!that) {
    // eslint-disable-next-line no-console
    console.error('请传入this')
    return info;
  }
  info.name = names.lang_id ? langIdData[names.lang_id] || names.lang_id : names[lang] || names[default_good_lang] || '';
  info.desc =  descs.lang_id ? langIdData[descs.lang_id] || descs.lang_id : descs[lang] || descs[default_good_lang] || '';
  return info;
}

export function assignObject (origin, target, ignoreKeys = []) {
  const object = cloneDeep(origin)
  for (const key in target) {
    if (!ignoreKeys.includes(key)) {
      object[key] = target[key]
    }
  }
  return object
}

const deppMerge = (target, source) => {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      target[key] =
        target[key] && getType(target).includes('Object')
          ? deppMerge(target[key], source[key])
          : (target[key] = source[key]);
    }
  }
  return target;
}

export function sleep(delay = 0) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

const mergeToObject = function(target, source) {
  const obj = cloneDeep(target)
  const origin = cloneDeep(source)
  const merge = (t, s) => {
    for (const key in s) {
      if (getType(s[key]).includes('Object')) {
        merge(t[key], source[key])
      } else {
        obj[key] = source[key]
      }
    }
  }
  merge(obj, origin)
  return obj
}

/*
*   判断是否是语言的两位字符
* */
export function isLangParams (lang){
  return /^[A-Za-z]{2}$/.test(lang)
}

/*
*  取两位字符判断是语言还是页面
* */
export function getLangPageId(lang, pageid, l, p) {
  if (!lang && pageid) {
    if(isLangParams(pageid)){
      l = pageid
      p = '/'
    }else{
      p = pageid
      l = ''
    }

  } else if (lang  && !pageid) {
    if(isLangParams(lang)){
      l = lang
      p = ''
    }else{
      p = lang
      l = ''
    }
  } else {
    l = lang
    p = pageid
  }

  return [l, p]
}
/*
* 通过路由对象获取语言和页面参数
* @{params} params  route.params
*  @{params} isProd  isProd
*  return {sid,lang,pageid}
* */
export function getNeedParamsFormRouteParams(params, isProd) {
  if(isProd === undefined){
    isProd = process.env.IS_BUILD_SITE
  }
  if (!params) {
    // eslint-disable-next-line
    console.warn('params不能为空')
    return params
  }
  const {lang, sid, pageid} = params
  let l = ''
  let n = null
  let s = isProd ? (GET_SITE_ID()): sid
  let p = '/'
  const isShopCart = PageEnum.SHOP_CART.includes(lang) || PageEnum.SHOP_CART.includes(pageid)
  const arr = getLangPageId(lang, pageid, l, p)
  // console.log(arr, 33)
  if (isShopCart && !isProd) {
    l = PageEnum.SHOP_CART.includes(arr[1]) ? '' : arr[1]
    p = '/'
    n = PageNameEnum.SHOP_CART
  } else if (!isProd && !sid && !lang && pageid) {
    s = pageid
  } else {
    l = arr[0]
    p = arr[1]
  }
  if (isProd && isShopCart) {
    n = PageNameEnum.SHOP_CART
    l = arr[1]
    p = '/'
  }


  return {
    sid: s,
    lang: l || '',
    pageid: p || '/',
    native: n
  }
}


export function objectRemoveKey(target, delKey) {
  const result = {}
  if (typeof target !== 'object') {
    return new Error('Must Be Object')
  }
  for (const key in target) {
    if (key !== delKey) {
      result[key] = target[key]
    }
  }
  return result
}

// 获取浏览器语言，转换成系统支持的语言
export function getSysLanguage() {
  let slang = ''
  const sysLang = window.navigator.language ? window.navigator.language.toLocaleLowerCase() : ''
  const langs = ['en', 'cn', 'ar', 'bg', 'pl', 'de', 'ru', 'fr', 'ko', 'cs', 'ro', 'pt', 'ja', 'th', 'tr', 'es', 'he', 'id', 'it', 'vi', 'tw']
  langs.forEach(item => {
    if (sysLang.includes(item)) {
      slang = item
    }
  })
  return slang
}

// 获取当前时间UTC时间
export function getNowUtcStamp() {
  const nowTime = new Date()
  const y = nowTime.getUTCFullYear()
  const m = nowTime.getUTCMonth() + 1
  const d = nowTime.getUTCDate()
  const h = nowTime.getUTCHours()
  const M = nowTime.getUTCMinutes()
  const s = nowTime.getUTCSeconds()
  // const utc = Date.UTC(y, m, d, h, M, s)
  const utc_time = `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${h < 10 ? `0${h}` : h}:${M < 10 ? `0${M}` : M}:${s < 10 ? `0${s}` : s}`
  const stamp = new Date(`${utc_time} GMT+0000`).valueOf() / 1000
  // console.log('now time utc', utc_time);
  return stamp
}

export const rename = (name, removeFlags) => {
  let result = name
  removeFlags.forEach(item => {
    result = result.replaceAll(item, '')
  })
  return result
}

export default {
  // deepClone,
  mergeToObject,
  transMoney,
  rename,
  deppMerge,
  isNumber,
  createId,
  getQueryString,
  getLang,
  getStoreLang,
  loadJsAsync,
  formatDate,
  transformLangToServer,
  getLangByip,
  getxfip,
  getPriceInfo,
  getGoodInfo,
  getLangFromPath,
  getNeedParamsFormRouteParams,
  getSysLanguage,
  getNowUtcStamp
}
